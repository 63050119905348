var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("Preview rich content"),
        visible: _vm.visible,
        "confirm-loading": false,
        "dialog-style": { top: "10px" },
        footer: null,
        width: "80%"
      },
      on: {
        cancel: function() {
          _vm.$emit("close")
        },
        afterClose: _vm.handleAfterClose
      }
    },
    [
      _c(
        "a-select",
        {
          staticStyle: { "min-width": "120px", "margin-bottom": "8px" },
          model: {
            value: _vm.device,
            callback: function($$v) {
              _vm.device = $$v
            },
            expression: "device"
          }
        },
        _vm._l(_vm.deviceOpts, function(item) {
          return _c(
            "a-select-option",
            { key: item.value, attrs: { value: item.value } },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass: "device",
          class: _vm.device,
          style:
            _vm.device === "device-iphone-14" ? "transform: scale(0.9)" : ""
        },
        [
          _c(
            "div",
            { staticClass: "device-frame", attrs: { id: "device-frame-id" } },
            [
              _c("iframe", {
                ref: "previewRef",
                staticClass: "preview-iframe",
                attrs: { frameborder: "0" }
              })
            ]
          ),
          _c("div", { staticClass: "device-stripe" }),
          _c("div", { staticClass: "device-header" }),
          _c("div", { staticClass: "device-sensors" }),
          _c("div", { staticClass: "device-btns" }),
          _c("div", { staticClass: "device-power" }),
          _c("div", { staticClass: "device-home" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }