var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { loading: false, size: "small" } },
    [
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", { staticStyle: { "margin-right": "12px" } }, [
            _vm._v(_vm._s(_vm.$t("Language")) + ":")
          ]),
          _c(
            "a-select",
            {
              staticStyle: { "min-width": "120px" },
              attrs: { value: _vm.codeLang, placeholder: _vm.$t("Select") },
              on: { change: _vm.changeLang }
            },
            [
              _c(
                "a-select-option",
                { key: _vm.codeLang, attrs: { value: _vm.codeLang } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(_vm.enumLangs[_vm.codeLang])) + " "
                  )
                ]
              ),
              _vm._l(_vm.langOptions, function(langKey) {
                return _c(
                  "a-select-option",
                  { key: langKey, attrs: { value: langKey } },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.enumLangs[langKey])) + " ")]
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "extra" }, slot: "extra" },
        [
          _vm.code.ragt && _vm.code.ragt.id
            ? _c("a-button", {
                attrs: { type: "primary", ghost: "", icon: "edit" },
                on: { click: _vm.openCode }
              })
            : _vm._e(),
          _c("a-button", {
            staticStyle: { "margin-left": "4px" },
            attrs: { type: "danger", ghost: "", icon: "delete" },
            on: { click: _vm.deleteCode }
          })
        ],
        1
      ),
      _vm.code.ragt && _vm.code.ragt.id
        ? [
            _c(
              "a-row",
              { attrs: { gutter: [32, 32], type: "flex", justify: "center" } },
              [
                _c("a-col", { attrs: { xs: 24, lg: 11 } }, [
                  _c("div", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("Plain Text version")))
                  ]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "This content is embedded into Univoice Code and will be used in no internet case"
                          )
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("a-col", { attrs: { xs: 24, lg: 11 } }, [
                  _c("div", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("HTML Rich version")))
                  ]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "This content is kept in Univoice Backend and will be downloaded to display when the user scans the code"
                          )
                        ) +
                        " "
                    )
                  ])
                ])
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: [32, 32], type: "flex", justify: "center" } },
              [
                _c(
                  "a-col",
                  { attrs: { xs: 24, lg: 11 } },
                  [
                    _c("a-textarea", {
                      staticStyle: { width: "100%", height: "400px" },
                      attrs: { value: _vm.code.text },
                      on: { change: _vm.changeCodeContent }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "grid",
                          "grid-template-columns": "1fr 1fr",
                          "grid-gap": "4px",
                          margin: "8px 0"
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { icon: "eye", type: "primary", ghost: "" },
                            on: { click: _vm.previewCutPlainText }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("Preview code content")) + " "
                            )
                          ]
                        ),
                        _vm.code.preText && _vm.code.preText !== _vm.code.text
                          ? _c(
                              "a-tooltip",
                              { attrs: { placement: "top" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("Previous version")))
                                  ])
                                ]),
                                _c(
                                  "a-button",
                                  {
                                    attrs: { icon: "undo" },
                                    on: { click: _vm.confirmRevertPlainText }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("Preview previous version")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.code.contentOverLimit
                      ? _c("p", { staticClass: "warning-content-limit" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$tc("warningContentLimit", 1)) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$tc("warningContentLimit", 0)) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { xs: 24, lg: 11 } },
                  [
                    _c("div", { staticClass: "preview-container" }, [
                      _c("iframe", {
                        attrs: {
                          width: "100%",
                          height: "100%",
                          frameborder: "0",
                          srcdoc:
                            _vm.code && _vm.code.ragt && _vm.code.ragt.html
                        }
                      })
                    ]),
                    _c(
                      "a-button",
                      {
                        staticStyle: { margin: "8px 0" },
                        attrs: { icon: "eye", type: "primary", ghost: "" },
                        on: {
                          click: function() {
                            _vm.visibleRichContentModal = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("Preview rich content")) + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "align-items": "center"
              }
            },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.openCode } },
                [_vm._v(" " + _vm._s(_vm.$t("Create from scratch")) + " ")]
              ),
              _c("span", { staticClass: "mx-sm" }, [
                _vm._v(_vm._s(_vm.$t("Or")))
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", ghost: "", icon: "upload" },
                  on: {
                    click: function() {
                      _vm.visibleInputHTMLModal = true
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("Create from an existing site")) + " "
                  )
                ]
              )
            ],
            1
          ),
      _vm.visibleInputHTMLModal
        ? _c("input-html-modal", {
            attrs: { visible: _vm.visibleInputHTMLModal },
            on: {
              ok: _vm.submitInputHTMLModal,
              cancel: function() {
                _vm.visibleInputHTMLModal = false
              }
            }
          })
        : _vm._e(),
      _vm.visibleRichContentModal
        ? _c("rich-content-modal", {
            attrs: {
              visible: _vm.visibleRichContentModal,
              ragtData: _vm.ragtData
            },
            on: {
              close: function() {
                _vm.visibleRichContentModal = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }