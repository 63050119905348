var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c("a-tree-select", {
            style: {
              flex: "auto",
              "margin-right": _vm.allowedAddNew ? "4px" : "0"
            },
            attrs: {
              value: _vm.categoryId,
              "dropdown-style": { maxHeight: "400px", overflow: "auto" },
              "tree-data": _vm.categories,
              "tree-default-expand-all": ""
            },
            on: { change: _vm.changeCategory }
          }),
          _vm.allowedAddNew
            ? _c(
                "a-tooltip",
                { attrs: { placement: "top" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("Add new category")))])
                  ]),
                  _c("a-button", {
                    attrs: { icon: "plus" },
                    on: {
                      click: function() {
                        _vm.categoryModalVisible = true
                      }
                    }
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.$t("Add Root Category"),
            "ok-text": _vm.$t("Create"),
            "cancel-text": _vm.$t("Cancel")
          },
          on: { ok: _vm.submitCategoryForm },
          model: {
            value: _vm.categoryModalVisible,
            callback: function($$v) {
              _vm.categoryModalVisible = $$v
            },
            expression: "categoryModalVisible"
          }
        },
        [
          _c(
            "a-form",
            { attrs: { layout: "horizontal", form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-col": { span: 6 },
                    "wrapper-col": { span: 18 },
                    label: _vm.$t("Category name")
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          {
                            rules: [
                              {
                                required: true,
                                message: this.$t("Please input category name!")
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n            'name',\n            { rules: [{ required: true, message: this.$t('Please input category name!') }] }\n          ]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }