//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ragtPlayerInfo } from '@/config/ragtPlayer';
export default {
  props: {
    visible: { type: Boolean, default: false },
    ragtData: { type: Object, default: () => ({}) }
  },

  computed: {
    deviceOpts() {
      return [
        {
          label: this.$t('PC Preview'),
          value: 'device-macbook-pro'
        },
        {
          label: this.$t('Mobile Preview'),
          value: 'device-iphone-14'
        }
      ];
    }
  },

  data() {
    return {
      device: 'device-macbook-pro'
    };
  },

  mounted() {
    if (this.ragtData && this.ragtData.html) {
      this.loadHtmlToIframe();
    }
  },

  methods: {
    loadHtmlToIframe() {
      this.$nextTick(() => {
        let previewRef = this.$refs.previewRef;
        let previewDocument = previewRef?.contentWindow || previewRef?.contentDocument;
        if (previewDocument?.document) {
          previewDocument = previewDocument?.document;
        }
        previewDocument?.write(this.ragtData.html);

        // Ragt player
        if (this.ragtData.id) {
          const libRagtPlayer = document.createElement('script');
          libRagtPlayer.type = 'module';
          libRagtPlayer.crossOrigin = 'true';
          libRagtPlayer.src = `${ragtPlayerInfo.script}?v=${Math.random()}`;
          previewDocument?.head?.appendChild(libRagtPlayer);

          // Create ragt-player component
          const ragtClientId = process.env.VUE_APP_RAGT_CLIENT_ID;
          const ragtPlayer = document.createElement(ragtPlayerInfo.name);
          ragtPlayer.setAttribute('ragt-client-id', ragtClientId);
          ragtPlayer.setAttribute('player-data', JSON.stringify(this.ragtData));
          previewDocument?.body?.prepend(ragtPlayer);
        }
      });
    },

    handleAfterClose() {
      console.log(this.$refs);
    }
  }
};
