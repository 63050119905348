var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-collapse",
        {
          attrs: { "default-active-key": "1", bordered: false },
          scopedSlots: _vm._u([
            {
              key: "expandIcon",
              fn: function(props) {
                return [
                  _c("a-icon", {
                    attrs: {
                      type: "caret-right",
                      rotate: props.isActive ? 90 : 0
                    }
                  })
                ]
              }
            }
          ])
        },
        [
          _c(
            "a-collapse-panel",
            {
              key: "1",
              style: {
                background: "#f7f7f7",
                "border-radius": "4px",
                "margin-bottom": "12px",
                border: 0,
                overflow: "hidden"
              },
              attrs: { header: _vm.$t("Basic information") }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: { display: "flex" },
                      attrs: {
                        xs: 24,
                        lg: { span: _vm.previewQRCode ? 20 : 24 }
                      }
                    },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: { bodyStyle: { padding: "18px 24px 0 24px" } }
                        },
                        [
                          _c(
                            "a-form",
                            { attrs: { layout: _vm.formLayout } },
                            [
                              _c(
                                "a-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _vm.codeId
                                    ? _c(
                                        "a-col",
                                        { attrs: { sm: 24, lg: 14 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticClass: "mb-none",
                                              attrs: {
                                                label: _vm.$t("ID"),
                                                "label-col":
                                                  _vm.formItemLayout.labelCol,
                                                "wrapper-col":
                                                  _vm.formItemLayout.wrapperCol
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "font-bold" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("formatId")(
                                                        _vm.codeId,
                                                        "CO"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.colorStatus && _vm.codeStatus
                                    ? _c(
                                        "a-col",
                                        { attrs: { sm: 24, lg: 10 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticClass: "mb-none",
                                              attrs: {
                                                label: _vm.$t("Status"),
                                                "label-col":
                                                  _vm.formItemLayout.labelCol,
                                                "wrapper-col":
                                                  _vm.formItemLayout.wrapperCol
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-bold",
                                                  style: {
                                                    color: _vm.colorStatus
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(_vm.codeStatus)
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, lg: 14 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            "has-feedback": "",
                                            label: _vm.$t("Code name"),
                                            "label-col":
                                              _vm.formItemLayout.labelCol,
                                            "wrapper-col":
                                              _vm.formItemLayout.wrapperCol,
                                            "validate-status":
                                              _vm.validateTitle,
                                            help:
                                              _vm.validateTitle === "error" &&
                                              _vm.$t("Title is required")
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            staticStyle: { width: "100%" },
                                            attrs: { value: _vm.title },
                                            on: { change: _vm.changeTitle }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, lg: 10 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("Created date"),
                                            "label-col":
                                              _vm.formItemLayout.labelCol,
                                            "wrapper-col":
                                              _vm.formItemLayout.wrapperCol
                                          }
                                        },
                                        [
                                          _c("a-date-picker", {
                                            attrs: {
                                              value: _vm.createdDate,
                                              format: "YYYY/MM/DD HH:mm",
                                              "show-time": { format: "HH:mm" }
                                            },
                                            on: {
                                              change: _vm.changeCreatedDate
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, lg: 14 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            "label-col":
                                              _vm.formItemLayout.labelCol,
                                            "wrapper-col":
                                              _vm.formItemLayout.wrapperCol,
                                            label: _vm.$t("Code size")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-radio-group",
                                            {
                                              attrs: { value: _vm.size },
                                              on: { change: _vm.changeCodeSize }
                                            },
                                            _vm._l(
                                              Object.keys(_vm.codeSizeOptions),
                                              function(key) {
                                                return _c(
                                                  "a-radio-button",
                                                  {
                                                    key: key,
                                                    attrs: {
                                                      value:
                                                        _vm.codeSizeOptions[key]
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(key) + " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "a-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-xs" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Code size")
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    Object.keys(
                                                      _vm.codeSizeOptions
                                                    ),
                                                    function(key) {
                                                      return _c(
                                                        "div",
                                                        { key: key },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "code.codeSize." +
                                                                    key
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c("a-icon", {
                                                staticClass: "ml-xs",
                                                staticStyle: {
                                                  "font-size": "22px"
                                                },
                                                attrs: {
                                                  type: "question-circle"
                                                }
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, lg: 10 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            "label-col":
                                              _vm.formItemLayout.labelCol,
                                            "wrapper-col":
                                              _vm.formItemLayout.wrapperCol,
                                            label: _vm.$t("EC error level")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-radio-group",
                                            {
                                              attrs: { value: _vm.ecLevel },
                                              on: {
                                                change: _vm.changeErrorEcLevel
                                              }
                                            },
                                            _vm._l(
                                              Object.keys(
                                                _vm.errorLevelOptions
                                              ),
                                              function(key) {
                                                return _c(
                                                  "a-radio-button",
                                                  {
                                                    key: key,
                                                    attrs: {
                                                      value:
                                                        _vm.errorLevelOptions[
                                                          key
                                                        ]
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(key) + " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "a-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-xs" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "EC error level"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    Object.keys(
                                                      _vm.errorLevelOptions
                                                    ),
                                                    function(key) {
                                                      return _c(
                                                        "div",
                                                        { key: key },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "code.errorLevel." +
                                                                    key
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c("a-icon", {
                                                staticClass: "ml-xs",
                                                staticStyle: {
                                                  "font-size": "22px"
                                                },
                                                attrs: {
                                                  type: "question-circle"
                                                }
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, lg: 14 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            "label-col":
                                              _vm.formItemLayout.labelCol,
                                            "wrapper-col":
                                              _vm.formItemLayout.wrapperCol,
                                            label: _vm.$t("Category")
                                          }
                                        },
                                        [
                                          _c("a-tree-select-custom", {
                                            attrs: {
                                              pageId: +_vm.pageId,
                                              categoryId: _vm.categoryId,
                                              "allowed-add-new": true
                                            },
                                            on: {
                                              changeCategory: _vm.changeCategory
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.previewQRCode
                    ? _c(
                        "a-col",
                        {
                          staticStyle: { display: "flex" },
                          attrs: { xs: 24, lg: 4 }
                        },
                        [
                          _c(
                            "a-card",
                            {
                              attrs: {
                                bodyStyle: {
                                  "text-align": "center",
                                  padding: "18px 24px 0 24px"
                                }
                              }
                            },
                            [
                              _c("image-review", {
                                attrs: { data: _vm.previewQRCode, size: 100 }
                              }),
                              _c("br"),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { padding: "0" },
                                  attrs: { type: "link" },
                                  on: {
                                    click: function() {
                                      return _vm.downloadQRCode(
                                        _vm.previewQRCode,
                                        _vm.codeId
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Download")) + " ")]
                              ),
                              _c("br"),
                              _vm.versions.length > 0
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: { padding: "0" },
                                      attrs: { type: "link" },
                                      on: {
                                        click: function() {
                                          return (_vm.versionModalVisible = true)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("*View past versions")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { footer: null },
          model: {
            value: _vm.versionModalVisible,
            callback: function($$v) {
              _vm.versionModalVisible = $$v
            },
            expression: "versionModalVisible"
          }
        },
        [
          _c("p", { staticClass: "title font-bold" }, [
            _vm._v(_vm._s(_vm.$t("Versions history")))
          ]),
          _c("a-list", {
            attrs: { "item-layout": "horizontal", "data-source": _vm.versions },
            scopedSlots: _vm._u([
              {
                key: "renderItem",
                fn: function(item) {
                  return _c(
                    "a-list-item",
                    {},
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { slot: "actions", type: "link" },
                          on: {
                            click: function($event) {
                              return _vm.downloadQRCode(
                                item.preview_qrcode,
                                item.code_id
                              )
                            }
                          },
                          slot: "actions"
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Download")) + " ")]
                      ),
                      _c(
                        "a-list-item-meta",
                        {
                          attrs: { description: _vm._f("dayjs")(item.created) }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.title))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }