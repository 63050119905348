var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.$t("Import file or input url"),
            visible: _vm.visible,
            "confirm-loading": _vm.loading
          },
          on: { ok: _vm.okInputHtmlModal, cancel: _vm.cancelInputHtmlModal }
        },
        [
          !_vm.simplifiedHTML
            ? _c(
                "a-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.activeKey,
                    callback: function($$v) {
                      _vm.activeKey = $$v
                    },
                    expression: "activeKey"
                  }
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "upload" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "tab" }, slot: "tab" },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" " + _vm._s(_vm.$t("Import")) + " ")
                        ],
                        1
                      ),
                      _c(
                        "a-upload-dragger",
                        {
                          attrs: {
                            name: "files",
                            accept: ".html",
                            "show-upload-list": false,
                            multiple: false,
                            disabled:
                              _vm.fileName && _vm.originalHtml ? true : false,
                            "custom-request": _vm.handleUploadFile,
                            "before-upload": _vm.beforeUpload
                          }
                        },
                        [
                          _c(
                            "a-spin",
                            {
                              attrs: {
                                spinning: _vm.loading,
                                tip: _vm.$t("Loading...")
                              }
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "ant-upload-drag-icon" },
                                [_c("a-icon", { attrs: { type: "inbox" } })],
                                1
                              ),
                              _c("p", { staticClass: "ant-upload-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "Click or drag file to this area to upload"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.fileName && _vm.originalHtml
                        ? _c("div", { staticClass: "file-card" }, [
                            _c("span", { staticClass: "file-card__name" }, [
                              _vm._v(_vm._s(_vm.fileName))
                            ]),
                            _c(
                              "span",
                              { staticClass: "file-card__action" },
                              [
                                _c(
                                  "a-tooltip",
                                  {
                                    attrs: {
                                      placement: "top",
                                      title: _vm.$t("Pickup content")
                                    }
                                  },
                                  [
                                    _c("a-button", {
                                      staticClass: "mr-xs",
                                      attrs: { icon: "select" },
                                      on: { click: _vm.openPickUpContent }
                                    })
                                  ],
                                  1
                                ),
                                _c("a-button", {
                                  attrs: { icon: "delete" },
                                  on: { click: _vm.deleteFile }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "url" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "tab" }, slot: "tab" },
                        [
                          _c("a-icon", { attrs: { type: "link" } }),
                          _vm._v(" " + _vm._s(_vm.$t("By URL")) + " ")
                        ],
                        1
                      ),
                      _c(
                        "a-form",
                        { attrs: { form: _vm.form } },
                        [
                          _c(
                            "a-form-item",
                            [
                              _c(
                                "a-row",
                                { attrs: { gutter: 8 } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 18 } },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "url",
                                              {
                                                rules: [
                                                  {
                                                    type: "url",
                                                    message: _vm.$t(
                                                      "The input is not valid URL!"
                                                    )
                                                  },
                                                  {
                                                    required: true,
                                                    message: _vm.$t(
                                                      "Please input your URL!"
                                                    )
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "[\n                    'url',\n                    {\n                      rules: [\n                        {\n                          type: 'url',\n                          message: $t('The input is not valid URL!')\n                        },\n                        {\n                          required: true,\n                          message: $t('Please input your URL!')\n                        }\n                      ]\n                    }\n                  ]"
                                          }
                                        ],
                                        attrs: {
                                          placeholder: _vm.$t("Input url"),
                                          "enter-button": "Save"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            placement: "top",
                                            "ok-text": _vm.$t("Yes"),
                                            "cancel-text": _vm.$t("No"),
                                            title: _vm.$t(
                                              "Are you sure you want to use this url?"
                                            )
                                          },
                                          on: { confirm: _vm.handleSubmitURL }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                type: "primary",
                                                ghost: "",
                                                icon: "save",
                                                loading: _vm.loading
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Save")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    background: "#fafafa",
                    border: "1px dashed #d9d9d9",
                    "border-radius": "2px",
                    padding: "16px",
                    "text-align": "center"
                  }
                },
                [
                  _c(
                    "p",
                    { staticStyle: { "font-size": "1rem", color: "black" } },
                    [_vm._v(_vm._s(_vm.$t("Imported content!")))]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger", ghost: "", icon: "delete" },
                          on: { click: _vm.deleteUploadedContent }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "4px" },
                          attrs: { type: "primary", ghost: "", icon: "eye" },
                          on: { click: _vm.openPreviewContentModal }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Preview content")) + " ")]
                      )
                    ],
                    1
                  )
                ]
              )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "60%",
            "body-style": { height: "80vh" },
            "dialog-style": { top: "20px" }
          },
          model: {
            value: _vm.visiblePreviewModal,
            callback: function($$v) {
              _vm.visiblePreviewModal = $$v
            },
            expression: "visiblePreviewModal"
          }
        },
        [
          _c("br"),
          _c("iframe", {
            staticStyle: { padding: "12px", "border-radius": "4px" },
            attrs: { srcdoc: _vm.simplifiedHTML, width: "100%", height: "100%" }
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-tooltip",
                { attrs: { placement: "top" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "This is a feature that allows you to limit the scope of content in the original site instead of taking it all."
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("div", { staticStyle: { "font-weight": "400" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Which needs Chrome Extension to enable. Please access"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://chrome.google.com/webstore/detail/css-selector/ajajpdillpelmpfbgeopndgfihgkagim",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" CSS Selector ")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("to install if not yet.")) + " "
                      )
                    ])
                  ]),
                  _c(
                    "a-button",
                    {
                      key: "back",
                      attrs: { type: "primary", icon: "select", ghost: "" },
                      on: { click: _vm.openPickUpContent }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Pickup content")) + " ")]
                  )
                ],
                2
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: {
                    click: function() {
                      _vm.visiblePreviewModal = false
                    }
                  }
                },
                [_vm._v(" OK ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }