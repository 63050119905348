var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      directives: [
        { name: "dragSource", rawName: "v-dragSource" },
        { name: "dropTarget", rawName: "v-dropTarget" }
      ],
      class: [_vm.pAttrs.className, _vm.isOver ? _vm.dropClassName : ""],
      style: Object.assign({}, { cursor: "move" }, _vm.pAttrs.style)
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }