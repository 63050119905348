//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { popupCenter } from '@/utils/util.js';
import localStorageKey from '@/constant/local-storage-key.js';

export default {
  props: {
    visible: { type: Boolean, default: false }
  },

  data() {
    return {
      loading: false,
      originalHtml: '',
      simplifiedHTML: '',
      url: '',
      win: null,
      timer: null,
      contentSelector: '',

      visiblePreviewModal: false,
      fileName: '',
      activeKey: 'upload'
    };
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'url' });
  },

  methods: {
    async readHTMLFile(file) {
      const self = this;
      this.loading = true;
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = () => {
        self.loading = false;
        self.originalHtml = reader.result;
        // self.confirmPickupContent();
      };
      reader.readAsText(file);
    },
    beforeUpload(file) {
      const fileExtend = file.name.split('.').pop();
      if (!['html'].includes(fileExtend)) {
        this.$notification.warning({
          message: this.$t('Click or drag file TYPES to this area to upload', { types: '(.html)' })
        });
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$notification.warning({
          message: this.$t('File must smaller than 10MB')
        });
      }
      return isLt2M;
    },
    handleUploadFile({ file }) {
      this.readHTMLFile(file);
    },
    handleSubmitURL(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.url = values.url;
          this.confirmPickupContent();
        }
      });
    },
    deleteUploadedContent() {
      this.simplifiedHTML = '';
      this.url = '';
      this.originalHtml = '';
    },
    openPickUpContent() {
      let link;
      if (this.url) {
        link = new URL(this.url);
      } else {
        link = new URL('about:blank');
      }
      link.searchParams.set('action', 'pick-css-selector');

      if (this.win && !this.win.closed) {
        this.win.close();
      }
      this.win = popupCenter({
        url: link,
        title: 'xtf',
        w: (2 * window.innerWidth) / 3,
        h: window.innerHeight - 100
      });

      if (this.activeKey === 'upload' && this.originalHtml) {
        this.win?.document.write(this.originalHtml);
      }

      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(async () => {
        if (this.win && this.win.closed) {
          clearInterval(this.timer);
          let text = (await navigator?.clipboard?.readText()) || '';
          // remove prefix
          if (text.includes('[CSS Selector]')) {
            text = text.replace('[CSS Selector]', '').trim();
            this.contentSelector = text;
            this.$message.success(this.$t('Picked up content successfully!'));
            this.handleSimplifiedHTML();
          } else {
            this.contentSelector = '';
            this.$message.error(this.$t("Can't pick up the content from this site!"));
          }
        }
      }, 1000);
    },
    async handleSimplifiedHTML() {
      if (this.form.getFieldError('url')?.length === 0) {
        this.url = this.form.getFieldValue('url');
      }
      if (!this.url && !this.originalHtml) return;
      try {
        this.loading = true;
        const data = await this.$s.api.ragtEditor.getSimplifiedHtml({
          type: this.activeKey === 'upload' ? 'html' : 'url',
          data: this.activeKey === 'upload' ? this.originalHtml : this.url,
          options: { contentSelectors: [this.contentSelector || 'body'] }
        });
        this.simplifiedHTML = data?.html || '';
      } catch (error) {
        console.log('Error: ', error);
      } finally {
        this.loading = false;
      }
    },
    async okInputHtmlModal() {
      if (!this.simplifiedHTML) {
        await this.handleSimplifiedHTML();
      }
      this.$emit('ok', this.simplifiedHTML);
    },
    cancelInputHtmlModal() {
      this.$emit('cancel');
    },
    openPreviewContentModal() {
      this.visiblePreviewModal = true;
    },
    confirmPickupContent() {
      if (this.$ls.get(localStorageKey.DONT_ASK_AGAIN_PICK_UP_CONTENT)) {
        this.openPickUpContent();
      } else {
        const self = this;
        this.$confirm({
          title: this.$t(
            'Would you like to limit the scope of content in the original site instead of taking it all?'
          ),
          content: () => (
            <div style="margin-top: 12px;">
              {self.$t('Which needs Chrome Extension to enable. Please access')}
              <a
                href="https://chrome.google.com/webstore/detail/css-selector/ajajpdillpelmpfbgeopndgfihgkagim"
                target="_blank"
              >
                CSS Selector
              </a>
              {self.$t('to install if not yet.')}
              <br />
              <br />
              <input type="checkbox" name="askAgain" onclick={this.changeAskAgain} />
              <label for="askAgain"> {this.$t("Don't ask me again")}</label>
            </div>
          ),
          onOk() {
            self.openPickUpContent();
          },
          onCancel() {
            self.handleSimplifiedHTML();
          }
        });
      }
    },
    changeAskAgain({ target }) {
      this.$ls.set(localStorageKey.DONT_ASK_AGAIN_PICK_UP_CONTENT, target.checked);
    },
    deleteFile() {
      this.fileName = '';
      this.originalHtml = '';
    }
  }
};
