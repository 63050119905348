//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    categoryId: { type: Number, default: null },
    pageId: { type: Number, default: null },
    allowedAddNew: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false,
      categoryModalVisible: false,
      form: this.$form.createForm(this, { name: 'coordinated' })
    };
  },
  computed: {
    ...mapGetters('category', ['categories'])
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchCategories(this.pageId);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('category', ['fetchCategories']),

    changeCategory(value) {
      this.$emit('changeCategory', value);
    },
    submitCategoryForm() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.addNewCategory(values.name);
        }
      });
    },
    async addNewCategory(categoryName) {
      try {
        const newCategory = {
          name: categoryName,
          isLeaf: true,
          parent_id: null
        };
        const result = await this.$s.api.page.createCategory(this.pageId, newCategory);
        await this.fetchCategories(this.pageId); // Reload category list
        this.changeCategory(result.id);
        this.$notification.success({ message: 'Successful' });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: error.message });
      } finally {
        this.categoryModalVisible = false;
      }
    }
  }
};
