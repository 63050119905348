//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import FileSaver from 'file-saver';
import ATreeSelectCustom from '@/components/tools/ATreeSelectCustom.vue';
export default {
  components: {
    ATreeSelectCustom,
    TreeSelect: () => import('@/components/tools/TreeSelect.vue'),
    ImageReview: () => import('@/components/tools/ImageReview.vue')
  },

  props: {
    pageId: { type: Number, default: 0 },
    codeId: { type: Number, default: null },
    title: { type: String, default: '' },
    size: { type: String, default: '2' },
    ecLevel: { type: String, default: '1' },
    createdDate: { type: Object, default: null },
    categoryId: { type: Number, default: null },
    previewQRCode: { type: String, default: '' },
    versions: { type: Array, default: () => [] },
    colorStatus: { type: String, default: '' },
    codeStatus: { type: String, default: '' }
  },

  data() {
    return {
      formLayout: 'horizontal',
      versionModalVisible: false
    };
  },

  computed: {
    ...mapGetters('category', ['categories']),

    formItemLayout() {
      return {
        labelCol: { xs: 6, lg: 8 },
        wrapperCol: { xs: 18, lg: 16 }
      };
    },
    codeSizeOptions() {
      return {
        XS: '0',
        S: '1',
        M: '2',
        L: '3',
        XL: '4'
      };
    },
    errorLevelOptions() {
      return {
        L: '0',
        M: '1',
        H: '2'
      };
    },
    validateTitle() {
      return this.title ? 'success' : 'error';
    }
  },

  methods: {
    changeTitle({ target }) {
      this.$emit('changeTitle', target.value);
    },
    changeCreatedDate(date) {
      this.$emit('changeCreatedDate', date);
    },
    changeCodeSize({ target }) {
      this.$emit('changeCodeSize', target.value);
    },
    changeErrorEcLevel({ target }) {
      this.$emit('changeErrorEcLevel', target.value);
    },
    changeCategory(value) {
      this.$emit('changeCategory', value);
    },
    downloadQRCode() {
      const timestamp = new Date().getTime();
      FileSaver.saveAs(
        `${this.previewQRCode}?time=${timestamp}`,
        `univoice_code${this.codeId}.bmp`
      );
    }
  }
};
