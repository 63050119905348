//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { Editor, EditorContent } from '@tiptap/vue-2'
  import StarterKit from '@tiptap/starter-kit'
  import Paragraph from '@tiptap/extension-paragraph'
  import Highlight from '@tiptap/extension-highlight'
  import { LANGUAGE_OPTIONS } from '@/enum'
import { mapActions, mapGetters } from 'vuex';

  export default {
    components: { EditorContent },
    props: {
      value: { type: String, default: '' },
      lang: { type: String, default: '' },
      dictionaryNameList: { type: Array, default: () => ([]) },
      engine: { type: String, default: "standard" }
    },
    data() {
      return {
        loading: false,
        editor: null,
        selectedText: '',
        selectedDictionaryOption: 'exist',
        timeout: null,
        gender: 'female',
        audio: null,
        isPlaying: false
      }
    },
    computed: {
      ...mapGetters('user', ['userInfo']),
      ...mapGetters('dictionary', ['dictionaryByLang', 'dictionaryByName']),
      dictionary() {
        let dictionary = {}
        this.dictionaryNameList?.forEach(dicName => {
          const dic = this.dictionaryByName(dicName)
          dictionary = {
            ...dictionary,
            ...dic.content.reduce((acc, item) => {
              acc[item.word] = item.reading
              return acc;
            }, {})
          }
        });
        return dictionary;
      },
      contentEditor() {
        let contentData = this.value
          .split('\n')
          .map(line => `<p>${line.replace(/\s+$/, '&nbsp')}</p>`) // Fix: space at the end of a paragraph is automatically removed when using the setContent API
          .join('');
        for (const [key, value] of Object.entries(this.dictionary)) {
          let regex = new RegExp(`\\b${key}\\b`, 'g');
          if (['.jpn', 'jpn', '.chi', 'chi', '.zho', 'zho', '.kor', 'kor'].includes(this.lang)) {
            regex = new RegExp(key, 'g');
          }
          contentData = contentData.replace(regex, `<mark>${key}</mark>`);
        }
        return contentData;
      },
      formItemLayout() {
        return {
          labelCol: { sm: { span: 24 }, lg: { span: 6 } },
          wrapperCol: { sm: { span: 24 }, lg: { span: 18 } },
        }
      },
      formTailLayout() {
        return {
          labelCol: { sm: { span: 24 }, lg: { span: 6 } },
          wrapperCol: { sm: { span: 24, offset: 0 }, lg: { span: 18, offset: 6 } },
        }
      },
      formItemOptions() {
        return {
          language: {
            rules: [{ required: true, message: 'Language is required!' }],
            initialValue: this.$t(LANGUAGE_OPTIONS[this.lang])
          },
          name: {
            rules: [{ required: true, message: 'Dictionary name is required!' }],
          },
          word: {
            rules: [{ required: true, message: 'Word is required!' }],
          },
          reading: {
            rules: [{ required: true, message: 'Reading is required!' }],
          },
          note: {
            rules: [{ required: false, message: 'Note is required!' }],
          },
        }
      },
      dictionaryOptions() {
        return this.dictionaryByLang(this.lang) || []
      },
    },
    beforeCreate() {
      this.form = this.$form.createForm(this, { name: 'create_dictionary' });
    },
    mounted() {
      this.editor = new Editor({
        content: this.contentEditor,
        extensions: [
          StarterKit,
          Highlight,
          Paragraph.configure({
            HTMLAttributes: {
              style: 'margin-bottom: 4px; line-height: normal'
            }
          })
        ],
        onCreate: () => {
          // Add event listener for right-click
          this.editor.options.element.addEventListener('contextmenu', (event) => {
            event.preventDefault(); // Prevent the default right-click menu

            // Check if there is selected text
            const selection = window.getSelection();
            if (selection && !selection.isCollapsed) {
              const text = selection.toString();
              this.selectedText = text
            }
          });
        },
        onUpdate: () => {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.$emit('change', this.editor.getText({ blockSeparator: '\n' }))
          }, 300);  // 300ms delay
        },
      })
    },
    methods: {
      ...mapActions('dictionary', ['addDictionary', 'updateDictionary']),
      handleSubmit(e) {
        e.preventDefault();
        const that = this
        this.loading = true
        this.form.validateFields((err, values) => {
          if (err) {
            that.loading = false
            return;
          }
          const { language, word, reading, name, note } = values
          if (this.selectedDictionaryOption === 'exist') {
            const dic = this.dictionaryByName(name)
            const payload = {
              ...dic,
              content: [
                ...dic.content,
                { word, reading, note, order: dic.content.length + 1 }
              ]
            }
            this.handleUpdateDictionary(payload)
          } else {
            const payload = {
              name,
              content: [{ word, reading, note, order: 1 }],
              language: this.lang,
              company_id: this.userInfo.company_id,
            }
            this.handleCreateDictionary(payload)
          }
        });
      },
      handleChangeDictionaryOption({ target }) {
        this.selectedDictionaryOption = target.value
        this.form.setFieldsValue({ name: '' });
      },
      handleCloseDictionaryCreationModal() {
        this.selectedText = ''
        this.form.resetFields()
      },
      async handleCreateDictionary(payload) {
        try {
          await this.$s.api.dictionary.createDictionary(payload);
          this.addDictionary(payload)
          this.$notification.success({
            message: this.$t("Create dictionary successfully")
          });
          this.handleCloseDictionaryCreationModal()
        } catch (error) {
          this.$notification.error({ message: error.message });
        }
        this.loading = false
      },
      async handleUpdateDictionary(payload) {
        try {
          const res = await this.$s.api.dictionary.updateDictionary(payload.sk, payload);
          this.updateDictionary(res)
          this.$notification.success({
            message: this.$t("Update dictionary successfully")
          });
          this.handleCloseDictionaryCreationModal()
        } catch (error) {
          this.$notification.error({ message: error.message });
        } finally {
          this.loading = false;
        }
      },
      handleChangeGender({ key }) {
        this.gender = key
      },
      async getSynthesizeSpeech(text) {
        const payload = {
          sentence: text,
          gender: this.gender || 'female',
          language: this.lang,
          textType: 'text',
          engine: this.engine || 'standard'
        };
        const res = await this.$s.api.sound.getSynthesizeSpeechStream(payload);
        if (!res?.mp3Stream?.data) return '';

        const bufferArray = res?.mp3Stream?.data;
        const audioBlob = new Blob([new Uint8Array(bufferArray)], {
          type: 'audio/mp3'
        });
        const url = URL.createObjectURL(audioBlob);
        return url;
      },
      async handlePlay() {
        if (this.audio) {
          this.audio.pause();
          this.audio = null;
        }
        try {
          this.isPlaying = true;
          const url = await this.getSynthesizeSpeech(this.form.getFieldValue('reading'));
          this.audio = new Audio(url);
          this.audio.play();
          this.audio.onended = () => this.handleEnd();
        } catch (error) {
          this.$notification.error({
            message: this.$t(error.TypeError) || this.$t('Network error')
          });
        }
      },
      handleEnd() {
        this.isPlaying = false;
      },
      handlePause() {
        this.isPlaying = false;
        this.audio.pause();
        this.audio = null;
      },
    },
    beforeDestroy() {
      this.editor.destroy()
      this.editor?.options?.element?.removeEventListener('contextmenu', null)
    },
    watch: {
      contentEditor: {
        handler() {
          if (this.contentEditor) {
            this.editor.commands.setContent(this.contentEditor)
          }
        }
      },
      selectedText: {
        handler() {
          if (this.selectedText && this.form) {
            this.$nextTick(() => {
              this.form.setFieldsValue({ word: this.selectedText })
            })
          }
        }
      }
    }
  }
