//
//
//

import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import SSMLModule from './SSMLModule';
Quill.register({ 'modules/rich-voice-editor': SSMLModule });
export default {
  props: {
    id: {
      type: [String, Number],
      default: '0'
    },
    value: {
      type: String,
      required: true,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    reflectText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null,
      ssmlToolbarOptions: {
        container: [
          ['ssml_speak'], // Add this.
          [{ ssml_break: ['weak', 'strong', 'x-strong'] }], // Add this.
          // ['ssml_emphasis'], // Add this.
          // ['ssml_whispering'], // Add this.
          [
            {
              ssml_language: [
                'ja-JP',
                'arb',
                'cmn-CN',
                'da-DK',
                'nl-NL',
                'en-AU',
                'en-GB',
                'en-IN',
                'en-NZ',
                'en-ZA',
                'en-US',
                'fr-FR',
                'fr-CA',
                'hi-IN',
                'de-DE',
                'is-IS',
                'it-IT',
                'ko-KR',
                'nb-NO',
                'pl-PL',
                'pt-BR',
                'pt-PT',
                'ro-RO',
                'ru-RU',
                'es-ES',
                'es-MX',
                'es-US'
              ]
            }
          ], // Add this.
          ['ssml_paragraph'], // Add this.
          [
            {
              ssml_sayas: ['spell-out', 'number', 'ordinal', 'digits', 'fraction', 'expletive']
            }
          ], // Add this.
          [
            {
              ssml_date: ['mdy', 'dmy', 'ymd', 'md', 'dm', 'ym', 'my', 'd', 'm', 'y']
            }
          ], // Add this.
          ['ssml_substitute'], // Add this.
          // ['ssml_breaths'], // Add this.
          ['ssml_phoneme'], // Add this.
          ['ssml_parse'] // Add this.
          // ["ssml_validate"] // Add this.
        ],
        handlers: {
          ssml_speak: function() {}, // Add this.
          ssml_break: function() {}, // Add this.
          // ssml_emphasis: function() {}, // Add this.
          ssml_language: function() {}, // Add this.
          ssml_paragraph: function() {}, // Add this.
          ssml_sayas: function() {}, // Add this.
          ssml_date: function() {}, // Add this.
          ssml_substitute: function() {}, // Add this.
          // ssml_breaths: function() {}, // Add this.
          ssml_phoneme: function() {}, // Add this.
          // ssml_whispering: function() {}, // Add this.
          ssml_parse: function() {} // Add this.
          // ssml_validate: function() {} // Add this.
        }
      }
    };
  },
  watch: {
    value(val) {
      if (this.editor && this.reflectText) {
        this.editor.setText(val);
      }
    }
  },
  computed: {
    editorId() {
      return 'ssml-editor-' + this.id;
    },
    enable() {
      return !this.disabled;
    }
  },
  beforeDestroy() {
    this.quill = null;
    delete this.quill;
  },
  mounted() {
    this.editor = new Quill(this.$refs[this.editorId], {
      modules: {
        toolbar: this.enable ? this.ssmlToolbarOptions : false,
        'rich-voice-editor': this.enable
      },
      theme: 'snow',
      placeholder: '<speak>Your SSML text here</speak>'
    });
    this.editor.setText(this.value);
    // disable editor
    if (this.disabled) {
      this.editor.disable();
      this.$refs[this.editorId].classList.add('disabled');
    }
    // Validate on input and emit state change
    let timeout = null;
    const vm = this;
    this.editor.on('text-change', function() {
      vm.update();
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        // get text w/o formatting from editor
        var editorText = vm.editor.getText(0);
        // remove amazon namespace before dom parser
        editorText = editorText.replace(/amazon:/g, '');
        // basic validation if text starts with <speak>
        if (editorText.startsWith('<speak>')) {
          // use browser dom parster to validate XML
          var oParser = new DOMParser();
          var oDOM = oParser.parseFromString(editorText, 'application/xml');
          // if error found in parsing result
          if (oDOM.getElementsByTagName('parsererror').length > 0) {
            vm.$refs[vm.editorId].classList.add('invaildSSML');
            vm.$emit('error', {
              message:
                'Invalid SSML: ' +
                oDOM.getElementsByTagName('parsererror')[0].childNodes[1].innerHTML,
              error: true
            });
          } else {
            vm.$refs[vm.editorId].classList.remove('invaildSSML');
            vm.$emit('error', {
              message: null,
              error: false
            });
          }
        } else {
          vm.$refs[vm.editorId].classList.add('invaildSSML');
          vm.$emit('error', {
            message: 'Invalid SSML: Missing <speak> tag',
            error: true
          });
        }
      }, 1000);
    });
  },

  methods: {
    update() {
      let text = this.editor.getText();
      if (this.isQuillEmpty()) text = '';
      this.$emit('change', text.replace(/\n+$/, ''));
    },
    isQuillEmpty() {
      if ((this.editor.getContents()['ops'] || []).length !== 1) {
        return false;
      }
      return this.editor.getText().trim().length === 0;
    },
    textOnly(ssml) {
      const regex = /<[^>]*>/g;
      return ssml.replace(regex, '');
    }
  }
};
