import Encoding from "encoding-japanese";
import { lastIndexOfRegex } from '@/components/_util/util'

const readMoreText = {
  jpn: "全文を読み上げる場合はインターネットに接続してから再度スキャンしてください。",
  vie: "Mở mạng di động để xem thêm",
  eng: "Turn on internet to read more"
}
const multiples = 2

/**
 * find all character which is non-SJIS
 *
 * @return: String - empty: if input is valid, otherwise: list all invalid characters
 */

export const convertSJISToUnicode = (data = "") => {
  // get current encoding of input.
  const detectedOrg = Encoding.detect(data);
  data = data.replace(/\xc2|\xa0/g, ''); // Non-breakable space is char 0xa0 (160 dec)

  return [...(data || "")]
    .map(char => {
      let ucd = char.charCodeAt(0);
      // convert current code to SJIS
      let shiftjisCode = Encoding.convert([ucd], "SJIS");

      // Detect the character encoding.
      const detected = Encoding.detect(shiftjisCode);
      if (detected === "SJIS") {
        return char;
      }

      const convertString = Encoding.convert(shiftjisCode, {
        to: detectedOrg,
        from: "SJIS",
        type: "string"
      });

      if (convertString !== char) {
        return convertString;
      }
      return char;
    })
    .join("");
};

export const removeNonASCII = function (str) {
  // https://bytefreaks.net/programming-2/javascript/javasript-remove-all-non-printable-and-all-non-ascii-characters-from-text
  const texts = str.split('\n').map((text) => {
    return text.replace(/[^\x20-\x7E]+/g, ''); // &nbsp;
  });
  return texts.join('\n');
};
export const dbLength = str => {
  let leg = str && str.length || 0;
  for (const i in str) {
    // eslint-disable-next-line 
    if (str.hasOwnProperty(i)) {
      var db = str[i].charCodeAt(0).toString(16).length == 4;
      if (db) leg += 1;
    }
  }
  return leg;
};
export const revertDbCutterLength = (str, dbLength) => {
  let textLeg = 0;
  let revertLen = dbLength
  for (const i in str) {
    textLeg += 1
    // eslint-disable-next-line 
    if (str.hasOwnProperty(i)) {
      var db = str[i].charCodeAt(0).toString(16).length == 4;
      if (db) revertLen -= 1;
    }
    revertLen -= 1;
    if (revertLen <= 0)
      break;
  }
  return textLeg;
};
export const subStringSpecialLangCase = (language, symSize = 4) => {
  const exceptSymsize = (symSize, subtractSize)=>{
    switch (parseInt(symSize)) {
      case 0: return 0;
      case 1: return 0;
      case 2: return subtractSize / 3
      case 3: return subtractSize / 2;
      case 4: return subtractSize / 1;
      default:
        return subtractSize;
    }
  }
  switch (language) {
    case "chi": return exceptSymsize(symSize, 200);
    case "zho": return exceptSymsize(symSize, 200);
    case "fre": return exceptSymsize(symSize, 300);
    case "spa": return exceptSymsize(symSize, 300);
    case "vie": return exceptSymsize(symSize, 300);
    default:
      return 0
  }
}
function removeSpecialCharacters(content, lang){
  // remove special characters
  switch (lang) {
    case 'jpn':
      content = convertSJISToUnicode(content);
      break;
    case 'eng':
      content = removeNonASCII(content);
      break;
    default:
      break;
  }
  return content
}
export function buildCodeContent(orgContent, lang, MAX_LEN = 1100 * multiples) {
  let text = orgContent
  let len = dbLength(text)
  let maxLen = MAX_LEN
  let existLang = Object.keys(readMoreText)
  let readMoreLang = existLang.includes(lang) ? lang : "eng"
  if (len > maxLen) {
    let predictLen = maxLen - dbLength(readMoreText[readMoreLang]) - 10// for some break line.
    if (predictLen < 0)
      return ""
    text = text.slice(
        0, revertDbCutterLength(text, predictLen)
      );
    let cuttedText = text.substr(0, Math.min(text.length, lastIndexOfRegex(text, /[$&+:;=?@#|<>.^*()%!。\n]/g) + 1))
    if (cuttedText)
      text = cuttedText
    else
        text = text.substr(0, Math.min(text.length, text.lastIndexOf(" ")))
    text = `${text}
      
      ${readMoreText[readMoreLang]}`.trim();
  }
  text = removeSpecialCharacters(text, lang)

  return text;
}

export const symSizeLength=(size)=> {
  switch (parseInt(size)) {
    case 0:
      return (55 * multiples); // 60
    case 1:
      return (270 * multiples); // 280
    case 2:
      return (610 * multiples); // 640
    case 3:
      return (760 * multiples); // 800
    case 4:
      return (1100 * multiples); // 1150
    default:
      break;
  }
}