var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-dropdown",
        [
          _c(
            "template",
            { slot: "overlay" },
            [
              _c(
                "a-menu",
                { on: { click: _vm.changeImportMode } },
                _vm._l(Object.keys(_vm.importModeOptions), function(item) {
                  return _c("a-menu-item", { key: item }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t(_vm.importModeOptions[item])) + " "
                    )
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-button",
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t(_vm.importModeOptions[_vm.importMode])) +
                  " "
              ),
              _c("a-icon", { attrs: { type: "down" } })
            ],
            1
          )
        ],
        2
      ),
      _c("br"),
      _c("br"),
      _c("a-spin", { attrs: { spinning: _vm.uploadLoading } }, [
        _c(
          "div",
          { staticClass: "spin-content" },
          [
            _c(
              "a-upload-dragger",
              {
                attrs: {
                  name: "files",
                  accept: ".docx",
                  multiple: true,
                  "show-upload-list": false,
                  "file-list": _vm.fileList,
                  "before-upload": _vm.beforeUpload
                }
              },
              [
                _c("div", { staticClass: "upload-container" }, [
                  _c(
                    "p",
                    { staticClass: "ant-upload-drag-icon upload-icon" },
                    [_c("a-icon", { attrs: { type: "inbox" } })],
                    1
                  ),
                  _c("p", { staticClass: "ant-upload-text upload-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "Click or drag file TYPES to this area to upload",
                            { types: "(.docx)" }
                          )
                        ) +
                        " "
                    )
                  ])
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c("br"),
      _vm.rowData.length > 0
        ? _c("p", { staticStyle: { color: "red" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "Codes are created in the order shown. You can change the order by dragging."
                  )
                ) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "DragDropContextProvider",
        { attrs: { backend: _vm.html5Backend } },
        [
          _c("a-table", {
            attrs: {
              "custom-row": _vm.customRow,
              components: _vm.components,
              "row-selection": _vm.rowSelection,
              columns: _vm.columns,
              "data-source": _vm.rowData,
              "row-key": function(record) {
                return record.id
              },
              locale: { emptyText: _vm.$t("No data") },
              pagination: false
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(text, record) {
                  return [
                    _c("a-input", {
                      staticStyle: { width: "100%" },
                      attrs: { value: text },
                      on: {
                        change: function(ref) {
                          var target = ref.target

                          return _vm.changeCodeTitle(target.value, record)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "content",
                fn: function(text, record) {
                  return [
                    _c("a-textarea", {
                      staticStyle: { width: "100%" },
                      attrs: { value: text },
                      on: {
                        change: function(ref) {
                          var target = ref.target

                          return _vm.changeCodeContent(target.value, record)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "lang",
                fn: function(text, record) {
                  return [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { value: text },
                        on: {
                          change: function(value) {
                            return _vm.changeCodeLang(value, record)
                          }
                        }
                      },
                      _vm._l(_vm.langOptions, function(item) {
                        return _c("a-select-option", { key: item.id }, [
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ])
                      }),
                      1
                    )
                  ]
                }
              },
              {
                key: "categoryId",
                fn: function(text, record) {
                  return [
                    _c("a-tree-select-custom", {
                      attrs: {
                        pageId: +_vm.pageId,
                        categoryId: text,
                        "allowed-add-new": false
                      },
                      on: {
                        changeCategory: function(value) {
                          return _vm.changeCategory(value, record)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "actions",
                fn: function(text, record) {
                  return [
                    _c(
                      "a-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: { type: "link" },
                        on: {
                          click: function() {
                            return _vm.handleRemoveCode(record)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Remove")) + " ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("br"),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 5 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "primary",
                    ghost: "",
                    icon: "save",
                    disabled: _vm.selectedRowKeys.length < 1
                  },
                  on: {
                    click: function() {
                      return _vm.saveCodesList("DRAFT")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save as draft")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 5 } },
            [
              _c(
                "a-popconfirm",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placement: "top",
                    title: _vm.$t("Are you sure to generate code?"),
                    "ok-text": _vm.$t("Yes"),
                    "cancel-text": _vm.$t("No"),
                    disabled: _vm.selectedRowKeys.length < 1
                  },
                  on: {
                    confirm: function() {
                      return _vm.saveCodesList("GENERATE")
                    }
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      class: { blink: true },
                      staticStyle: { width: "100%" },
                      attrs: { disabled: _vm.selectedRowKeys.length < 1 }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "15px",
                          height: "15px",
                          "margin-right": "8px"
                        },
                        attrs: { src: require("@/assets/logo.png") }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("Generate")) + " ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.$t("Delimiter list") },
          model: {
            value: _vm.delimiterConfigModalVisible,
            callback: function($$v) {
              _vm.delimiterConfigModalVisible = $$v
            },
            expression: "delimiterConfigModalVisible"
          }
        },
        [
          _c("p", { staticStyle: { color: "#f2b507" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    '*You can enter "xx" for dynamic string replacement. Example: [String xx], [xx String], [String xx String]'
                  )
                ) +
                " "
            )
          ]),
          _vm._l(_vm.delimiters, function(delimiter) {
            return _c(
              "div",
              { key: delimiter, staticStyle: { display: "inline-block" } },
              [
                delimiter.length > 20
                  ? _c(
                      "a-tooltip",
                      { key: delimiter, attrs: { title: delimiter } },
                      [
                        _c(
                          "a-tag",
                          {
                            staticStyle: { margin: "4px 4px 4px 0" },
                            attrs: { closable: "" },
                            on: {
                              close: function() {
                                return _vm.removeDelimiter(delimiter)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(delimiter.slice(0, 20) + "...") + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "a-tag",
                      {
                        staticStyle: { margin: "4px 4px 4px 0" },
                        attrs: { closable: "" },
                        on: {
                          close: function() {
                            return _vm.removeDelimiter(delimiter)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(delimiter) + " ")]
                    )
              ],
              1
            )
          }),
          _vm.inputDelimiterVisible
            ? _c("a-input", {
                ref: "delimiterInputRef",
                style: { width: "100px" },
                attrs: { type: "text", size: "small" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addNewDelimiter($event)
                  }
                },
                model: {
                  value: _vm.newDelimiter,
                  callback: function($$v) {
                    _vm.newDelimiter = $$v
                  },
                  expression: "newDelimiter"
                }
              })
            : _c(
                "a-tag",
                {
                  staticStyle: {
                    background: "#fff",
                    borderStyle: "dashed",
                    margin: "4px 4px 4px 0"
                  },
                  on: { click: _vm.showInputDelimiter }
                },
                [
                  _c("a-icon", { attrs: { type: "plus" } }),
                  _vm._v(" " + _vm._s(_vm.$t("New delimiter")) + " ")
                ],
                1
              ),
          _c(
            "div",
            { staticStyle: { "margin-top": "12px" } },
            [
              _c(
                "a-checkbox",
                {
                  model: {
                    value: _vm.excludeDelimiter,
                    callback: function($$v) {
                      _vm.excludeDelimiter = $$v
                    },
                    expression: "excludeDelimiter"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Exclude delimiter")))]
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function() {
                      _vm.delimiterConfigModalVisible = false
                    }
                  }
                },
                [_vm._v(" OK ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }