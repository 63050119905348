//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LANGUAGES } from '@/enum';
import { mapGetters } from 'vuex';
import { DocTransformer } from '@/data/transformer';
import { DragDropContextProvider } from 'vue-react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableRow from '@/components/common/DraggableRow.vue';
import ATreeSelectCustom from '@/components/tools/ATreeSelectCustom.vue';

let id = 0;

export default {
  components: {
    DragDropContextProvider,
    ATreeSelectCustom
  },

  props: {
    categoryId: { type: Number, default: null }
  },

  data() {
    return {
      rowData: [],
      selectedRowKeys: [],
      importModeOptions: {
        '1': 'Import all texts as a single Voice code',
        // '2': 'Import texts per page as multiple Voice codes',
        '3': 'Import texts with delimiter texts as multiple Voice codes'
      },
      importMode: '1',
      delimiterConfigModalVisible: false,
      delimiters: this.$ls.get('uv-delimiters') || [],
      newDelimiter: '',
      inputDelimiterVisible: false,
      uploadLoading: false,
      excludeDelimiter: false,

      html5Backend: HTML5Backend,
      components: {
        body: { row: DraggableRow }
      },
      fileList: []
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    pageId() {
      return this.$route.params.pageId;
    },
    langOptions() {
      return LANGUAGES[this.currentLanguage];
    },
    columns() {
      return [
        {
          title: this.$t('Code Name'),
          dataIndex: 'title',
          key: 'title',
          scopedSlots: { customRender: 'name' },
          width: '20%'
        },
        {
          title: this.$t('Code Content'),
          dataIndex: 'text',
          key: 'text',
          ellipsis: true,
          scopedSlots: { customRender: 'content' },
          width: '35%'
        },
        {
          title: this.$t('Language'),
          dataIndex: 'lang',
          key: 'lang',
          scopedSlots: { customRender: 'lang' },
          width: '15%'
        },
        {
          title: this.$t('Category'),
          dataIndex: 'categoryId',
          key: 'categoryId',
          scopedSlots: { customRender: 'categoryId' },
          width: '20%'
        },
        {
          title: this.$t('Actions'),
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          width: '10%'
        }
      ];
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'delete-selected-code',
            text: this.$t('Delete selected code'),
            onSelect: () => {
              const deletedIds = new Set(this.selectedRowKeys);
              this.rowData = this.rowData.filter(item => !deletedIds.has(item.id));
              this.selectedRowKeys = [];
            }
          }
        ]
      };
    }
  },

  methods: {
    addNewDelimiter() {
      if (this.newDelimiter) {
        this.delimiters = [...Array.from(new Set([...this.delimiters, this.newDelimiter]))];
        this.$ls.set('uv-delimiters', this.delimiters);
        this.newDelimiter = '';
        this.inputDelimiterVisible = false;
      }
    },
    removeDelimiter(delimiter) {
      this.delimiters = this.delimiters.filter(item => item !== delimiter) || [];
      this.$ls.set('uv-delimiters', this.delimiters);
    },
    showInputDelimiter() {
      this.inputDelimiterVisible = true;
      this.$nextTick(function() {
        this.$refs.delimiterInputRef.focus();
      });
    },
    changeImportMode({ key }) {
      this.importMode = key;
      if (key === '3') {
        this.delimiterConfigModalVisible = true;
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = [...selectedRowKeys];
    },
    handleRemoveCode(code) {
      this.rowData = this.rowData.filter(item => item.id !== code.id);
      this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== code.id);
    },
    beforeUpload(file, fileList) {
      // const fileExtend = file.name.split('.').pop();
      // if (!['docx'].includes(fileExtend)) {
      //   this.$notification.warning({
      //     message: this.$t('Click or drag file TYPES to this area to upload', { types: '(.docx)' })
      //   });
      //   return false;
      // }
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   this.$notification.warning({
      //     message: this.$t('File must smaller than 10MB')
      //   });
      //   return false
      // }
      this.fileList = [...this.fileList, file];
      if (this.fileList.length === fileList.length) {
        this.extractCodesFromFileList();
      }
      return false;
    },
    async extractCodesFromFileList() {
      for (let i = 0; i < this.fileList.length; i++) {
        await this.extractCodeFromFile(this.fileList[i]);
      }
      this.fileList = [];
    },
    async extractCodeFromFile(file) {
      try {
        const { title, listText } = await new DocTransformer().parse(file);
        const fullText = listText.reduce((acc, item) => (acc += item), '');
        let newData;
        // Import all texts as a single Voice code
        if (this.importMode === '1' || (this.importMode === '3' && this.delimiters.length < 1)) {
          newData = [{ title, text: fullText, categoryId: this.categoryId || null }];
        }
        // Import texts per page as multiple Voice codes
        else if (this.importMode === '2') {
          newData = listText.map(item => {
            return {
              title: `${item.split('\n')[0] || title}`,
              text: item,
              categoryId: this.categoryId || null
            };
          });
        }
        // Import texts with delimiter texts as multiple Voice codes
        else if (this.importMode === '3') {
          let fixedDelimiterList = [],
            dynamicDelimiterList = [];
          this.delimiters.forEach(element => {
            if (element.includes('xx')) {
              dynamicDelimiterList.push(element);
            } else {
              fixedDelimiterList.push(element);
            }
          });
          const separator = '$SEPARATOR';
          const { excludeDelimiter } = this;
          newData = fullText;
          if (fixedDelimiterList.length > 0) {
            const fixedRegexPattern = new RegExp(
              '(' +
                fixedDelimiterList
                  .map(item => item.replace(/([\[|\]|\{|\}|\(|\)])/g, '\\$1'))
                  .join('|') +
                ')',
              'g'
            );
            newData = newData.replace(fixedRegexPattern, function(matched) {
              if (excludeDelimiter) {
                return `${separator}`;
              }
              return `${separator}${matched}`;
            });
          }
          dynamicDelimiterList.forEach(item => {
            const newItem = item.replace(/([\[|\]|\{|\}|\(|\)])/g, '\\$1').replace('xx', '.*?');
            const regexPattern = new RegExp('(' + newItem + ')', 'g');
            if (excludeDelimiter) {
              newData = newData.replace(regexPattern, function(matched) {
                const temp = item
                  .replace(/([\[|\]|\{|\}|\(|\)])/g, '\\$1')
                  .replace('xx', '\(.*?\)');
                const newMatched = (matched.match(new RegExp(temp)) || [])[1] || '';
                return `${separator}${newMatched}`;
              });
            } else {
              newData = newData.replace(regexPattern, `${separator}$1`);
            }
          });
          newData = newData
            .split(separator)
            .filter(item => item)
            .map(item => ({
              title: `${item.split('\n')[0] || title}`,
              text: item,
              categoryId: this.categoryId || null
            }));
        }
        this.rowData = [
          ...this.rowData,
          ...newData.map(item => ({ ...item, id: id++, lang: 'jpn' }))
        ];
      } catch (error) {
        this.$notification.error({ message: this.$t('Import failed!') });
      }
    },
    changeCodeTitle(value, code) {
      this.rowData = this.rowData.map(item =>
        item.id === code.id ? { ...item, title: value } : item
      );
    },
    changeCodeContent(value, code) {
      this.rowData = this.rowData.map(item =>
        item.id === code.id ? { ...item, text: value } : item
      );
    },
    changeCodeLang(value, code) {
      this.rowData = this.rowData.map(item =>
        item.id === code.id ? { ...item, lang: value } : item
      );
    },
    changeCategory(value, code) {
      this.rowData = this.rowData.map(item =>
        item.id === code.id ? { ...item, categoryId: value } : item
      );
    },
    saveCodesList(status) {
      const selectedIds = new Set(this.selectedRowKeys);
      const codes = this.rowData.filter(item => selectedIds.has(item.id));
      this.$emit('onSaveCodesList', { status, codes });
    },
    moveRow(dragIndex, hoverIndex) {
      const draggingRow = this.rowData[dragIndex];
      const data = [...this.rowData];
      data.splice(dragIndex, 1);
      data.splice(hoverIndex, 0, draggingRow);
      this.rowData = data;
    },
    customRow(record, index) {
      return {
        attrs: {
          index,
          moveRow: this.moveRow
        }
      };
    }
  }
};
