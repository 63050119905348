var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code-item" },
    [
      _c(
        "a-form",
        [
          _c(
            "div",
            { staticClass: "content-between" },
            [
              _c("div", [
                _c("span", { staticClass: "mr-xs" }, [
                  _vm._v(_vm._s(_vm.$t("Language")))
                ]),
                _c("span", [_vm._v(_vm._s(_vm.code.id))])
              ]),
              _c("a-button", {
                attrs: { icon: "delete" },
                on: { click: _vm.deleteCode }
              })
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            {
              staticStyle: { "margin-bottom": "8px" },
              attrs: { type: "flex", justify: "start" }
            },
            [
              _c("a-col", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(_vm.$t("Language")) + ":")
              ]),
              _c(
                "a-col",
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { "min-width": "120px" },
                      attrs: {
                        value: _vm.code.lang,
                        placeholder: _vm.$t("Select")
                      },
                      on: { change: _vm.changeLanguage }
                    },
                    [
                      _c("a-select-option", { key: _vm.code.lang }, [
                        _vm._v(_vm._s(_vm.$t(_vm.code.lang)))
                      ]),
                      _vm._l(_vm.langOptions, function(item) {
                        return _c("a-select-option", { key: item }, [
                          _vm._v(" " + _vm._s(_vm.$t(item)) + " ")
                        ])
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              staticClass: "content-start",
              staticStyle: { "margin-bottom": "8px" }
            },
            [
              _c(
                "a-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("SSML Enable")))])
                      ]),
                      _c("span", { staticClass: "pr-md" }, [
                        _vm._v(_vm._s(_vm.$t("Advanced speak(SSML)")) + ":")
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 16 } },
                [
                  _c("a-switch", {
                    attrs: { checked: _vm.code.ssml.is_enable },
                    on: { change: _vm.changeSSMLAsSpeech }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "content-start" },
            [
              _c("a-col", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(_vm.$t("Content")) + ":")
              ]),
              _c(
                "a-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "validate-status": _vm.validateStatusContent,
                        help: _vm.errorMessageContent
                      }
                    },
                    [
                      _c(
                        "a-tabs",
                        {
                          attrs: { type: "card" },
                          on: { change: _vm.onChangeTabEditor }
                        },
                        [
                          _c(
                            "a-tab-pane",
                            { key: "1" },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "tab" }, slot: "tab" },
                                [_vm._v(" " + _vm._s(_vm.$t("Text")) + " ")]
                              ),
                              _c("a-textarea", {
                                attrs: { rows: 10, value: _vm.code.text },
                                on: { change: _vm.changeContent }
                              }),
                              !_vm.code.isPlaySpeech && _vm.code.text
                                ? _c(
                                    "a-dropdown-button",
                                    {
                                      attrs: {
                                        trigger: ["click"],
                                        placement: "bottomLeft"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handlePlaySpeech()
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "play-circle" }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.genderLabel) + " "
                                      ),
                                      _c(
                                        "a-menu",
                                        {
                                          attrs: { slot: "overlay" },
                                          on: { click: _vm.handleChangeGender },
                                          slot: "overlay",
                                          model: {
                                            value: _vm.voiceGender,
                                            callback: function($$v) {
                                              _vm.voiceGender = $$v
                                            },
                                            expression: "voiceGender"
                                          }
                                        },
                                        [
                                          _c(
                                            "a-menu-item",
                                            { key: "female" },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "user" }
                                              }),
                                              _vm._v(_vm._s(_vm.$t("Female")))
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-menu-item",
                                            { key: "male" },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "user" }
                                              }),
                                              _vm._v(_vm._s(_vm.$t("Male")))
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("a-icon", {
                                        attrs: {
                                          slot: "icon",
                                          type: "setting"
                                        },
                                        slot: "icon"
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.code.isPlaySpeech && _vm.code.text
                                ? _c("a-button", {
                                    attrs: { icon: "pause-circle" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handlePauseSpeech()
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "a-tab-pane",
                            { key: "2" },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "tab" }, slot: "tab" },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("ssmlIntroduction"))
                                          )
                                        ])
                                      ]),
                                      _vm._v(" SSML ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("ssml-editor", {
                                    attrs: {
                                      value: _vm.code.ssml.text,
                                      id: _vm.code.lang,
                                      reflectText: _vm.reflectText
                                    },
                                    on: {
                                      change: _vm.changeSSMLContent,
                                      error: _vm.catchSSMLError
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.code.isPlaySpeech && _vm.code.ssml.text
                                ? _c(
                                    "a-dropdown-button",
                                    {
                                      attrs: {
                                        trigger: ["click"],
                                        placement: "bottomLeft"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handlePlaySpeech()
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "play-circle" }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.genderLabel) + " "
                                      ),
                                      _c(
                                        "a-menu",
                                        {
                                          attrs: { slot: "overlay" },
                                          on: { click: _vm.handleChangeGender },
                                          slot: "overlay",
                                          model: {
                                            value: _vm.voiceGender,
                                            callback: function($$v) {
                                              _vm.voiceGender = $$v
                                            },
                                            expression: "voiceGender"
                                          }
                                        },
                                        [
                                          _c(
                                            "a-menu-item",
                                            { key: "female" },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "user" }
                                              }),
                                              _vm._v(_vm._s(_vm.$t("Female")))
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-menu-item",
                                            { key: "male" },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "user" }
                                              }),
                                              _vm._v(_vm._s(_vm.$t("Male")))
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("a-icon", {
                                        attrs: {
                                          slot: "icon",
                                          type: "setting"
                                        },
                                        slot: "icon"
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.code.isPlaySpeech && _vm.code.ssml.text
                                ? _c("a-button", {
                                    attrs: { icon: "pause-circle" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handlePauseSpeech()
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.code.contentOverLimit
                        ? _c("div", { staticClass: "warning-content-limit" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$tc("warningContentLimit", 1)) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$tc("warningContentLimit", 0)) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.code.contentOverLimit
                        ? _c(
                            "a-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.openPreviewCodeContent()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "Preview the content stored in the code"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }